import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/working_dir/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import Component from 'react-component-component';
import { Grid, GridItem, GridRow } from '../../src';
import { exampleItem, exampleStyles } from './helpers/testGridData';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "grid"
    }}>{`Grid`}</h1>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import Grid from '@mfrm/mfcl/Grid'
import GridRow from '@mfrm/mfcl/GridRow'
import GridItem from '@mfrm/mfcl/GridItem'
`}</code></pre>
    <p>{`You must import the css file if you want to use the Grid`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`/* in your Global scss file: */
@import '@mfrm/mfcl/layout-styles.scss';
`}</code></pre>
    <h2 {...{
      "id": "description"
    }}>{`Description`}</h2>
    <p>{`Grid component used to deliver consistent content layouts across different screen sizes`}</p>
    <h2 {...{
      "id": "properties"
    }}>{`Properties`}</h2>
    <Props of={Grid} mdxType="Props" />
    <h2 {...{
      "id": "basic-usage"
    }}>{`Basic Usage`}</h2>
    <Playground __position={1} __code={'{/* size is 1 (1/12 of the grid), so 12 items should be in each row */}\n<Grid\n  defaultSize={1}\n  createGridItems\n  gridStyles={{ backgroundColor: \'rgba(180, 247, 255, 0.6\' }}\n>\n  <div style={exampleStyles} />\n  <div style={exampleStyles} />\n  <div style={exampleStyles} />\n  <div style={exampleStyles} />\n  <div style={exampleStyles} />\n  <div style={exampleStyles} />\n  <div style={exampleStyles} />\n  <div style={exampleStyles} />\n  <div style={exampleStyles} />\n  <div style={exampleStyles} />\n  <div style={exampleStyles} />\n  <div style={exampleStyles} />\n</Grid>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Component,
      Grid,
      GridItem,
      GridRow,
      exampleItem,
      exampleStyles,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {/* size is 1 (1/12 of the grid), so 12 items should be in each row */}
  <Grid defaultSize={1} createGridItems gridStyles={{
        backgroundColor: 'rgba(180, 247, 255, 0.6'
      }} mdxType="Grid">
    <div style={exampleStyles} />
    <div style={exampleStyles} />
    <div style={exampleStyles} />
    <div style={exampleStyles} />
    <div style={exampleStyles} />
    <div style={exampleStyles} />
    <div style={exampleStyles} />
    <div style={exampleStyles} />
    <div style={exampleStyles} />
    <div style={exampleStyles} />
    <div style={exampleStyles} />
    <div style={exampleStyles} />
  </Grid>
    </Playground>
    <h2 {...{
      "id": "using-grid-item"
    }}>{`Using Grid Item`}</h2>
    <Playground __position={2} __code={'<Grid gridStyles={{ backgroundColor: \'rgba(180, 247, 255, 0.6\' }}>\n  <GridItem defaultSize={6}>{exampleItem}</GridItem>\n  <GridItem defaultSize={3}>{exampleItem}</GridItem>\n  <GridItem defaultSize={3}>{exampleItem}</GridItem>\n  <GridItem defaultSize={3}>{exampleItem}</GridItem>\n  <GridItem defaultSize={3}>{exampleItem}</GridItem>\n  <GridItem defaultSize={6}>{exampleItem}</GridItem>\n</Grid>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Component,
      Grid,
      GridItem,
      GridRow,
      exampleItem,
      exampleStyles,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Grid gridStyles={{
        backgroundColor: 'rgba(180, 247, 255, 0.6'
      }} mdxType="Grid">
    <GridItem defaultSize={6} mdxType="GridItem">{exampleItem}</GridItem>
    <GridItem defaultSize={3} mdxType="GridItem">{exampleItem}</GridItem>
    <GridItem defaultSize={3} mdxType="GridItem">{exampleItem}</GridItem>
    <GridItem defaultSize={3} mdxType="GridItem">{exampleItem}</GridItem>
    <GridItem defaultSize={3} mdxType="GridItem">{exampleItem}</GridItem>
    <GridItem defaultSize={6} mdxType="GridItem">{exampleItem}</GridItem>
  </Grid>
    </Playground>
    <h2 {...{
      "id": "using-grid-row"
    }}>{`Using Grid Row`}</h2>
    <Playground __position={3} __code={'<Grid\n  createRows={false}\n  gridStyles={{ backgroundColor: \'rgba(180, 247, 255, 0.6\' }}\n>\n  <GridRow>\n    <GridItem defaultSize={2}>{exampleItem}</GridItem>\n    <GridItem defaultSize={2}>{exampleItem}</GridItem>\n    <GridItem defaultSize={2}>{exampleItem}</GridItem>\n  </GridRow>\n  {/* Items in this roll will be center aligned */}\n  <GridRow alignRow={\'center\'}>\n    <GridItem defaultSize={3}>{exampleItem}</GridItem>\n    <GridItem defaultSize={3}>{exampleItem}</GridItem>\n    <GridItem defaultSize={3}>{exampleItem}</GridItem>\n  </GridRow>\n</Grid>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Component,
      Grid,
      GridItem,
      GridRow,
      exampleItem,
      exampleStyles,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Grid createRows={false} gridStyles={{
        backgroundColor: 'rgba(180, 247, 255, 0.6'
      }} mdxType="Grid">
    <GridRow mdxType="GridRow">
      <GridItem defaultSize={2} mdxType="GridItem">{exampleItem}</GridItem>
      <GridItem defaultSize={2} mdxType="GridItem">{exampleItem}</GridItem>
      <GridItem defaultSize={2} mdxType="GridItem">{exampleItem}</GridItem>
    </GridRow>
    {/* Items in this roll will be center aligned */}
    <GridRow alignRow={'center'} mdxType="GridRow">
      <GridItem defaultSize={3} mdxType="GridItem">{exampleItem}</GridItem>
      <GridItem defaultSize={3} mdxType="GridItem">{exampleItem}</GridItem>
      <GridItem defaultSize={3} mdxType="GridItem">{exampleItem}</GridItem>
    </GridRow>
  </Grid>
    </Playground>
    <h2 {...{
      "id": "griditem-properties"
    }}>{`GridItem Properties`}</h2>
    <Props of={GridItem} mdxType="Props" />
    <h2 {...{
      "id": "gridrow-properties"
    }}>{`GridRow Properties`}</h2>
    <Props of={GridRow} mdxType="Props" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      